import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      className={className}
    >
      <path d="M358.4 55.467c-18.825 0-34.133 15.309-34.133 34.133v6.17l-199.441 79.77a8.552 8.552 0 0 0-5.359 7.927v119.467a8.532 8.532 0 0 0 5.359 7.919l199.441 79.77v6.178c0 18.825 15.309 34.133 34.133 34.133s34.133-15.309 34.133-34.133V89.6c0-18.825-15.308-34.133-34.133-34.133zM375.467 396.8c0 9.412-7.654 17.067-17.067 17.067s-17.067-7.654-17.067-17.067v-11.947a8.53 8.53 0 0 0-5.367-7.927l-199.433-79.77V189.244l199.433-79.77a8.546 8.546 0 0 0 5.367-7.927V89.6c0-9.412 7.654-17.067 17.067-17.067s17.067 7.654 17.067 17.067v307.2z" />
      <path d="M128 174.933H68.267C30.626 174.933 0 205.559 0 243.2s30.626 68.267 68.267 68.267H128a8.53 8.53 0 0 0 8.533-8.533V183.467c0-4.711-3.823-8.534-8.533-8.534zM119.467 294.4h-51.2c-28.237 0-51.2-22.963-51.2-51.2S40.03 192 68.267 192h51.2v102.4zM437.897 209.502l-51.2-17.067a8.55 8.55 0 0 0-7.689 1.178 8.527 8.527 0 0 0-3.541 6.921v85.333a8.556 8.556 0 0 0 3.541 6.929A8.567 8.567 0 0 0 384 294.4c.905 0 1.818-.145 2.697-.444l51.2-17.067a8.508 8.508 0 0 0 5.837-8.09v-51.2a8.535 8.535 0 0 0-5.837-8.097zm-11.23 53.145l-34.133 11.375v-61.653l34.133 11.375v38.903z" />
      <path d="M169.873 409.54l-8.567-8.576-25.028-100.096a8.535 8.535 0 0 0-8.277-6.468H59.733a8.562 8.562 0 0 0-6.69 3.226c-1.621 2.048-2.219 4.719-1.621 7.262l34.133 145.067a8.547 8.547 0 0 0 8.303 6.579c.538 0 1.075-.051 1.621-.162l64.085-12.373.256-.051a20.569 20.569 0 0 0 9.916-5.41 20.349 20.349 0 0 0 6.084-14.447c.035-5.471-2.064-10.625-5.947-14.551zm-12.126 16.845a3.418 3.418 0 0 1-1.579.887L100.3 438.067 70.51 311.466h50.825l23.996 95.932a8.542 8.542 0 0 0 2.244 3.968l10.206 10.206c.862.862.981 1.877.981 2.415a3.428 3.428 0 0 1-1.015 2.398zM460.8 234.667H512v17.067h-51.2zM456.393 159.106l42.661-25.605 8.782 14.633-42.661 25.605zM456.47 327.313l8.78-14.635 42.668 25.6-8.781 14.636zM324.267 98.133h17.067v290.133h-17.067zM85.333 209.067H102.4V243.2H85.333zM51.2 209.067h17.067V243.2H51.2z" />
    </svg>
  );
}
