import React, { Fragment } from 'react';
import cx from 'classnames';

export const compose = (...fns) =>
  fns.reduce((f, g) => (...args) => f(g(...args)));

export const withStyles = (...classNames) => Component => ({
  className,
  ...rest
}) => <Component {...rest} className={cx(...classNames, className)} />;

export function addLineBreak(text) {
  return (
    <Fragment>
      {text.split('<br>').map((element, index) =>
        index === 0 ? (
          element
        ) : (
          <Fragment key={index}>
            <br />
            {element}
          </Fragment>
        )
      )}
    </Fragment>
  );
}

export function br(text) {
  return (
    <Fragment>
      {text.split('<br>').map((element, index) =>
        index === 0 ? (
          element
        ) : (
          <Fragment key={index}>
            <br />
            {element}
          </Fragment>
        )
      )}
    </Fragment>
  );
}

export const wait = (delay = 0) => new Promise(res => setTimeout(res, delay));
