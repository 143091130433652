import React from 'react';
import cx from 'classnames';
import styles from './styles.module.css';

export default function({ title, items, component: Component, className }) {
  return (
    <div className={cx(styles.container, className)}>
      <b className={styles.title}>{title}</b>
      <ul className={styles.list}>
        {items.map((props, index) => (
          <Component {...props} key={index} />
        ))}
      </ul>
    </div>
  );
}
