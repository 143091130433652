import React from 'react';
import styles from './styles.module.css';

export default function({ isActive, onClick }) {
  const innerClassName = isActive
    ? `${styles.inner} ${styles.active}`
    : styles.inner;

  return (
    <div onClick={onClick} className={styles.hamburger}>
      <div className={innerClassName}>
        <div className={styles.line} />
      </div>
    </div>
  );
}
