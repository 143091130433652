import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styles from './styles.module.css';

const Item = ({ children }) => <li className={styles.item}>{children}</li>;

export const Inner = props => (
  <Item>
    <GatsbyLink className={styles.link} to={props.href}>
      {props.text}
    </GatsbyLink>
  </Item>
);

export const Outer = ({ Icon, href, text }) => (
  <Item>
    <Icon className={styles.icon} height={12} width={12} />
    <a className={styles.link} href={href}>
      {text}
    </a>
  </Item>
);
