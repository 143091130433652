import React from 'react';
import styles from './styles.module.css';

export default function HeaderContainer({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
}
