import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480 480"
      width={width}
      height={height}
      className={className}
    >
      <path d="M392 0H88C65.92.026 48.026 17.92 48 40v216c.026 22.08 17.92 39.974 40 40h109.392l-11.424 40H160v16h160v-16h-25.968l-11.424-40H392c22.08-.026 39.974-17.92 40-40V40c-.026-22.08-17.92-39.974-40-40zM202.608 336l11.424-40h51.936l11.424 40h-74.784zM416 256c0 13.255-10.745 24-24 24H88c-13.255 0-24-10.745-24-24v-16h352v16zm0-32H64V40c0-13.255 10.745-24 24-24h304c13.255 0 24 10.745 24 24v184zM304 368H80a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h224a8 8 0 0 0 8-8v-96a8 8 0 0 0-8-8zm-8 96H88v-80h208v80z" />
      <path d="M104 432h16v16h-16zM104 400h16v16h-16zM136 400h16v16h-16zM168 400h16v16h-16zM232 400h16v16h-16zM200 400h16v16h-16zM264 432h16v16h-16zM264 400h16v16h-16zM136 432h112v16H136zM368 368c-22.091 0-40 17.909-40 40v32c0 22.091 17.909 40 40 40s40-17.909 40-40v-32c0-22.091-17.909-40-40-40zm24 72c0 13.255-10.745 24-24 24s-24-10.745-24-24v-32c0-13.255 10.745-24 24-24s24 10.745 24 24v32z" />
      <path d="M360 400h16v16h-16zM216.243 181.904l32.03-111.936 15.376 4.4-32.03 111.936zM197.12 94.144l-10.24-12.288-48 40a8 8 0 0 0 0 12.288l48 40 10.24-12.288L156.496 128l40.624-33.856zM342.14 122.876a7.991 7.991 0 0 0-1.02-1.02l-48-40-10.24 12.288L323.504 128l-40.624 33.856 10.24 12.288 48-40a8 8 0 0 0 1.02-11.268z" />
    </svg>
  );
}
