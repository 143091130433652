import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { PHONE, reachGoal } from '@utils/yandex';
import { Phone3 as Icon } from '@icons';

import styles from './styles.module.css';
import { phone } from '@data/contacts.json';

const Link = ({ children }) => (
  <a className={styles.link} onClick={() => reachGoal(PHONE)} href={phone.href}>
    {children}
  </a>
);

export default ({ iconSize }) => {
  const shouldShowLabel = useMediaQuery({ minWidth: 1110 });

  const LinkWithIcon = ({ children }) => (
    <Link>
      <Icon height={iconSize} width={iconSize} className={styles.icon} />
      {children}
    </Link>
  );

  const component = shouldShowLabel ? (
    <LinkWithIcon>
      <span className={styles.label}>{phone.text}</span>
    </LinkWithIcon>
  ) : (
    <LinkWithIcon />
  );

  return component;
};
