import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 58"
      width={width}
      height={height}
      className={className}
    >
      <path d="M44 28a1 1 0 0 0-1 1v6c0 7.72-6.28 14-14 14s-14-6.28-14-14v-6a1 1 0 1 0-2 0v6c0 8.485 6.644 15.429 15 15.949V56h-5a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2h-5v-5.051c8.356-.52 15-7.465 15-15.949v-6a1 1 0 0 0-1-1z" />
      <path d="M29 46c6.065 0 11-4.935 11-11V11c0-6.065-4.935-11-11-11S18 4.935 18 11v24c0 6.065 4.935 11 11 11zm-9-35c0-4.963 4.038-9 9-9s9 4.037 9 9v24c0 4.963-4.038 9-9 9s-9-4.037-9-9V11z" />
    </svg>
  );
}
