import React from 'react';

import Navigation from '../components/Navigation';
import UserList from '../components/UserList';

import Container from '../components/HeaderContainer';
import ControlPanel from '../components/ControlPanel';

export default function HeaderDesktopLayout() {
  return (
    <Container>
      <ControlPanel>
        <Navigation />
        <UserList />
      </ControlPanel>
    </Container>
  );
}
