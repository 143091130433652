import React from 'react';
import styles from './styles.module.css';
import { Login, Phone } from '../UserListItem';

const iconSize = 16;

const buttons = [Phone, Login].map(Component => (
  <Component iconSize={iconSize} />
));

export default function UserList({ items = [] }) {
  return (
    <ul className={styles.list}>
      {[...buttons, ...items].map((item, index) => (
        <li key={index} className={styles.item}>
          {item}
        </li>
      ))}
    </ul>
  );
}
