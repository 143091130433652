import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width}
      height={height}
      className={className}
    >
      <g>
        <path d="M50.976 20.694c-.528-9-7.947-16.194-16.892-16.194-5.43 0-10.688 2.663-13.945 7.008-.075-.039-.154-.066-.23-.102a9.322 9.322 0 0 0-.604-.269 9.062 9.062 0 0 0-.962-.317c-.115-.031-.229-.063-.345-.089a9.567 9.567 0 0 0-.687-.125c-.101-.015-.2-.035-.302-.046A9.096 9.096 0 0 0 16 10.5c-4.963 0-9 4.037-9 9 0 .129.008.255.017.381C2.857 22.148 0 26.899 0 31.654 0 38.737 5.762 44.5 12.845 44.5H18a1 1 0 1 0 0-2h-5.155C6.865 42.5 2 37.635 2 31.654c0-4.154 2.705-8.466 6.433-10.253L9 21.13v-.63c0-.12.008-.242.015-.365l.011-.185-.013-.194C9.007 19.671 9 19.586 9 19.5c0-3.859 3.141-7 7-7a6.995 6.995 0 0 1 1.15.103c.267.044.53.102.789.177.035.01.071.017.106.027.285.087.563.197.835.321.071.032.14.067.21.101A6.995 6.995 0 0 1 23 19.5a1 1 0 1 0 2 0 8.98 8.98 0 0 0-3.2-6.871C24.667 8.879 29.388 6.5 34.084 6.5c7.745 0 14.178 6.135 14.849 13.888-1.021-.072-2.552-.109-4.083.124a1 1 0 0 0 .3 1.977c2.227-.337 4.548-.021 4.684-.002C54.49 23.372 58 27.661 58 32.472 58 38.001 53.501 42.5 47.972 42.5H44a1 1 0 1 0 0 2h3.972C54.604 44.5 60 39.104 60 32.472c0-5.489-3.827-10.412-9.024-11.778z" />
        <path d="M38.293 45.793L32 52.086V31.5a1 1 0 1 0-2 0v20.586l-6.293-6.293a.999.999 0 1 0-1.414 1.414l7.999 7.999a.99.99 0 0 0 .326.217.991.991 0 0 0 1.09-.217l7.999-7.999a.999.999 0 1 0-1.414-1.414z" />
      </g>
    </svg>
  );
}
