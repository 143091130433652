import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      width={width}
      height={height}
      className={className}
    >
      <path d="M10 4H0v52h60V4H10zM8 54H2V6h6v48zm50 0H10V6h48v48z" />
      <path d="M21.5 47c3.533 0 6.411-2.617 6.492-5.86.001-.014.008-.025.008-.038V23.854l17-2.684v9.51A6.765 6.765 0 0 0 40.5 29c-3.584 0-6.5 2.691-6.5 6s2.916 6 6.5 6 6.5-2.691 6.5-6V14a.999.999 0 0 0-1.157-.988l-19 3A1 1 0 0 0 26 17v19.68A6.765 6.765 0 0 0 21.5 35c-3.584 0-6.5 2.691-6.5 6s2.916 6 6.5 6zm19-8c-2.481 0-4.5-1.794-4.5-4s2.019-4 4.5-4 4.5 1.794 4.5 4-2.019 4-4.5 4zM28 17.854l17-2.685v3.976L28 21.83v-3.976zM21.5 37c2.481 0 4.5 1.794 4.5 4s-2.019 4-4.5 4-4.5-1.794-4.5-4 2.019-4 4.5-4z" />
    </svg>
  );
}
