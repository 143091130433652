import React from 'react';
import { Link } from 'gatsby';
import black from './svgs/imstream_logo_black.svg';
import white from './svgs/imstream_logo_white.svg';

export default function({
  className,
  imgClassName,
  height = '30px',
  width,
  color = 'black',
}) {
  const src = color === 'black' ? black : white;
  return (
    <Link className={className} to="/">
      <img
        className={imgClassName}
        height={height}
        width={width}
        src={src}
        alt="IMstream"
      />
    </Link>
  );
}
