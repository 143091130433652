import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 60 60"
      height={height}
      width={width}
      className={className}
    >
      <path d="M48.014 42.889l-9.553-4.776A2.63 2.63 0 0 1 37 35.748v-3.381c.229-.28.47-.599.719-.951a22.886 22.886 0 0 0 2.954-5.799A3.968 3.968 0 0 0 43 22v-4c0-.963-.36-1.896-1-2.625v-5.319c.056-.55.276-3.824-2.092-6.525C37.854 1.188 34.521 0 30 0s-7.854 1.188-9.908 3.53C17.724 6.231 17.944 9.506 18 10.056v5.319c-.64.729-1 1.662-1 2.625v4c0 1.217.553 2.352 1.497 3.109.916 3.627 2.833 6.36 3.503 7.237v3.309c0 .968-.528 1.856-1.377 2.32l-8.921 4.866A9.018 9.018 0 0 0 7 50.762V54c0 4.746 15.045 6 23 6s23-1.254 23-6v-3.043a8.973 8.973 0 0 0-4.986-8.068zM51 54c0 1.357-7.412 4-21 4S9 55.357 9 54v-3.238a7.016 7.016 0 0 1 3.659-6.164l8.921-4.866A4.644 4.644 0 0 0 24 35.655v-4.019l-.233-.278c-.024-.029-2.475-2.994-3.41-7.065l-.091-.396-.341-.22A1.995 1.995 0 0 1 19 22v-4c0-.561.238-1.084.67-1.475l.33-.297V10l-.009-.131c-.003-.027-.343-2.799 1.605-5.021C23.253 2.958 26.081 2 30 2c3.905 0 6.727.951 8.386 2.828 1.947 2.201 1.625 5.017 1.623 5.041L40 16.228l.33.298c.432.39.67.913.67 1.474v4c0 .873-.572 1.637-1.422 1.899l-.498.153-.16.495a20.934 20.934 0 0 1-2.834 5.713c-.297.421-.586.794-.837 1.079l-.249.284v4.125c0 1.77.983 3.361 2.566 4.153l9.553 4.776A6.982 6.982 0 0 1 51 50.957V54z" />
    </svg>
  );
}
