import React from 'react';
import Menu from '@components/HamburgerMenu';

import styles from './styles.module.css';

import Navigation from '../components/Navigation';
import UserList from '../components/UserList';

import Container from '../components/HeaderContainer';
import ControlPanel from '../components/ControlPanel';

export default function HeaderMobileLayout() {
  const [isOpen, setOpen] = React.useState(false);
  const items = [<Menu iconSize={22} onClick={() => setOpen(!isOpen)} />];

  return (
    <Container>
      <ControlPanel>
        <UserList items={items} />
      </ControlPanel>
      <div className={styles.dropdown} style={{ height: isOpen ? 232 : 0 }}>
        <Navigation />
      </div>
    </Container>
  );
}
