import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -4 483.678 483"
      height={height}
      width={width}
      className={className}
    >
      <path d="M481.336 174.363L309.656 2.68c-2.289-2.285-5.73-2.97-8.719-1.73S296 5.101 296 8.335v67.68c-163.398.187-295.816 132.601-296 296v96a7.999 7.999 0 0 0 6.121 7.785 7.995 7.995 0 0 0 9-4.13l18.238-35.519c47.743-93.601 144.047-152.414 249.121-152.136H296v67.683a8 8 0 0 0 13.656 5.652l171.68-171.68a7.992 7.992 0 0 0 0-11.308zM312 332.387v-56.371a8 8 0 0 0-8-8h-21.52c-111.07-.301-212.878 61.863-263.351 160.8L16 434.93v-62.914c.176-154.567 125.434-279.82 280-280h8c4.418 0 8-3.578 8-8V27.648l152.367 152.368zm0 0" />
    </svg>
  );
}
