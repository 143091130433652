import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480.004 480.004"
      width={width}
      height={height}
      className={className}
    >
      <path d="M479.202 292.692l.072-.024-78.808-171.952 19.112-9.512-7.152-14.32-18.632 9.28-2.52-5.496a8 8 0 0 0-14.544 0l-2.248 4.912a102.908 102.908 0 0 0-43.28-9.576h-67.2v-16h16v-16H266.25l5.752-23.016c4.416-17.673-6.33-35.58-24.003-39.997-17.673-4.416-35.58 6.33-39.997 24.003a32.984 32.984 0 0 0 0 15.993l5.752 23.016h-13.752v16h16v16h-67.16a102.911 102.911 0 0 0-43.32 9.6l-2.248-4.912a8 8 0 0 0-14.544 0l-2.52 5.496-18.632-9.32-7.152 14.336 19.112 9.552L.73 292.668a7.813 7.813 0 0 0-.728 3.336c.035 30.913 25.087 55.965 56 56h80c30.913-.035 55.965-25.087 56-56a7.82 7.82 0 0 0-.8-3.312l-79.024-172.6a87.017 87.017 0 0 1 36.664-8.088h67.16v292.688l-27.312 27.312h-28.688c-22.08.026-39.974 17.92-40 40a8 8 0 0 0 8 8h224a8 8 0 0 0 8-8c-.026-22.08-17.92-39.974-40-40h-28.688l-27.312-27.312V112.004h67.2a87.017 87.017 0 0 1 36.664 8.088L288.73 292.668a7.813 7.813 0 0 0-.728 3.336c.035 30.913 25.087 55.965 56 56h80c30.913-.035 55.965-25.087 56-56a7.79 7.79 0 0 0-.8-3.312zm-343.2 43.312h-80c-19.002-.021-35.375-13.387-39.2-32h158.4c-3.824 18.613-20.198 31.979-39.2 32zm35.536-48H20.466l73.488-160.328c1.342.36 2.754.36 4.096 0l73.488 160.328zm55.08-265.472c6.23-7.392 17.273-8.333 24.665-2.103.759.64 1.463 1.344 2.103 2.103a16.887 16.887 0 0 1 3.096 14.576l-6.728 26.896H230.25l-6.728-26.896a16.89 16.89 0 0 1 3.096-14.576zm5.384 73.472v-16h16v16h-16zm16 16v288h-16v-288h16zm72 336a24.047 24.047 0 0 1 22.632 16H137.37a24.047 24.047 0 0 1 22.632-16h160zm-51.312-16h-57.376l16-16h25.376l16 16zM381.938 127.7a5.461 5.461 0 0 0 4.048-.168l73.552 160.472H308.466L381.938 127.7zm42.064 208.304h-80c-19.002-.021-35.375-13.387-39.2-32h158.4c-3.824 18.613-20.198 31.979-39.2 32z" />
    </svg>
  );
}
