import React, { Fragment } from 'react';
import Header from '@components/Header/';
import Footer from '@components/Footer/';
import './layout.css';
import styles from './styles.module.css';

export default ({ children }) => {
  return (
    <Fragment>
      <div className={styles.container}>
        <Header />
        {children}
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};
