import React from 'react';
import { useMediaQuery } from 'react-responsive';

import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

export default function Header() {
  const isMobile = useMediaQuery({ maxWidth: 787 });
  const Component = isMobile ? MobileLayout : DesktopLayout;

  return <Component />;
}
