import { wait } from '@utils';

export const FULL = 'bfull';
export const TRIAL = 'bfree';
export const LOGIN = 'lk';
export const PHONE = 'call';

export async function reachGoal(goal, counter = 0) {
  const { yaCounter: ym } = window;

  if (!ym) {
    if (counter === 10) {
      console.error('could not connect to yandex metrik');
      return;
    }

    await wait(1000);
    return reachGoal(goal, counter + 1);
  }

  return ym.reachGoal(goal);
}
