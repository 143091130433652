import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      className={className}
    >
      <circle cx="255.997" cy="256" r="255.997" fill="#41b4e6" />
      <path
        d="M512 256.003c0-6.238-.235-12.419-.673-18.546L405.228 131.36 106.772 248.759l114.191 114.192 1.498 5.392 1.939-1.955.008.008-1.947 1.947L348.778 494.66C444.298 457.5 512 364.663 512 256.003z"
        fill="#0091c8"
      />
      <path
        fill="#fff"
        d="M231.138 293.3l115.691 87.347 58.399-249.287-298.457 117.399 90.817 30.081 165.743-111.176z"
      />
      <path
        fill="#d2d2d7"
        d="M197.588 278.84l24.873 89.504 8.677-75.044 132.193-125.636z"
      />
      <path fill="#b9b9be" d="M268.738 321.688l-46.277 46.656 8.677-75.044z" />
    </svg>
  );
}
