import { Inner, Outer } from './Item';
import { Phone2, Telegram1, WhatsApp1, Mail1 } from '@icons';

import contact from '@data/contacts.json';
import {
  services,
  about,
  contacts,
  subscribe,
  cafe,
  fitness,
  shop,
  usage,
  serviceExamples,
} from '@data/navigation.json';

export default {
  tagline: 'Новое звучание вашего бизнеса',
  copyright: `2010 - ${new Date().getFullYear()}`,
  columns: {
    contact: {
      title: 'Контакты',
      component: Outer,
      items: [
        {
          ...contact.phone.russia,
          Icon: Phone2,
        },
        {
          ...contact.telegram,
          Icon: Telegram1,
        },
        {
          ...contact.whatsapp,
          Icon: WhatsApp1,
        },
        {
          ...contact.mail,
          Icon: Mail1,
        },
      ],
    },
    link: {
      title: 'Ссылки',
      component: Inner,
      items: [serviceExamples, services, about, contacts, subscribe, usage],
    },
    service: {
      title: 'Услуги и решения',
      component: Inner,
      items: [cafe, shop, fitness],
    },
  },
};
