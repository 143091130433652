import React from 'react';
import { Link } from 'gatsby';
import { container, list, item, link, active } from './styles.module.css';

import navigation from './data';

function Item({ props: { text, href } }) {
  return (
    <li className={item}>
      <Link
        className={link}
        activeClassName={active}
        partiallyActive={true}
        to={href}
      >
        {text}
      </Link>
    </li>
  );
}

export default function({ props }) {
  return (
    <nav className={container}>
      <ul className={list}>
        {navigation.map((item, index) => (
          <Item props={item} key={index} />
        ))}
      </ul>
    </nav>
  );
}
