import React from 'react';
import styles from './styles.module.css';
import data from './data.js';

import Logo from '@components/Logo/';
import Column from './Column';

const { columns } = data;

export default function() {
  return (
    <footer className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.info}>
          <Logo className={styles.logo} color={'white'} height={25} />
          <div className={styles.tagline}>{data.tagline}</div>
          <div className={styles.copyright}>&copy; {data.copyright}</div>
        </div>
        <nav className={styles.nav}>
          <Column {...columns.contact} className={styles.contact} />
          <Column {...columns.link} className={styles.link} />
          <Column {...columns.service} className={styles.service} />
        </nav>
      </div>
    </footer>
  );
}
