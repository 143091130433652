import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width}
      height={height}
      className={className}
    >
      <path d="M63.022 26.237c-.665-.798-1.601-1.255-2.568-1.255H59v-1.174c0-2.109-1.832-3.826-4.083-3.826H53v-2.167c0-1.02-.435-2.015-1.192-2.731-1.506-1.423-4.109-1.423-5.615 0A3.784 3.784 0 0 0 45 17.816v10.167H19V17.816c0-1.02-.434-2.015-1.192-2.731-1.506-1.423-4.109-1.423-5.615 0A3.78 3.78 0 0 0 11 17.816v2.167H8.85a3.976 3.976 0 0 0-2.693 1.028A3.529 3.529 0 0 0 5 23.594v1.388H3.56c-.972 0-1.912.459-2.579 1.26-.632.76-.981 1.759-.981 2.812v5.856c0 1.053.349 2.052.982 2.812.667.801 1.607 1.26 2.578 1.26H5v1.388c0 .966.422 1.908 1.159 2.585a3.971 3.971 0 0 0 2.691 1.027H11v2.167c0 1.02.435 2.015 1.192 2.73.753.711 1.75 1.103 2.808 1.103s2.055-.392 2.808-1.103A3.778 3.778 0 0 0 19 46.149V35.982h26v10.167c0 1.02.435 2.015 1.192 2.73A4.068 4.068 0 0 0 49 49.982a4.068 4.068 0 0 0 2.808-1.103A3.782 3.782 0 0 0 53 46.149v-2.167h1.917c2.251 0 4.083-1.716 4.083-3.826v-1.174h1.454c.967 0 1.903-.458 2.569-1.255.63-.757.978-1.751.978-2.8v-5.889c-.001-1.049-.348-2.044-.979-2.801zM3.56 36.982c-.381 0-.751-.192-1.041-.54-.33-.395-.519-.954-.519-1.532v-5.856c0-.578.189-1.136.519-1.532.29-.348.66-.54 1.041-.54H5v10H3.56zm5.29 5c-.503 0-.992-.183-1.339-.501A1.504 1.504 0 0 1 7 40.37V23.594c0-.414.181-.809.509-1.11.35-.319.838-.502 1.341-.502H11v20H8.85zM17 46.149c0 .479-.201.932-.565 1.276-.75.708-2.119.708-2.869 0A1.747 1.747 0 0 1 13 46.149V17.815c0-.479.201-.932.565-1.276A2.105 2.105 0 0 1 15 15.982c.537 0 1.06.203 1.435.557.364.344.565.798.565 1.277V46.149zm2-12.167v-4h26v4H19zm32 12.167c0 .479-.201.932-.565 1.276-.75.708-2.119.708-2.869 0A1.747 1.747 0 0 1 47 46.149V17.816c0-.479.201-.932.565-1.276A2.105 2.105 0 0 1 49 15.983c.537 0 1.06.203 1.435.557.364.343.565.797.565 1.276V46.149zm6-5.993c0 1.007-.934 1.826-2.083 1.826H53v-20h1.917c1.148 0 2.083.819 2.083 1.826v16.348zm5-5.23c0 .574-.188 1.127-.514 1.52-.288.346-.655.536-1.032.536H59v-10h1.454c.377 0 .744.19 1.032.536.327.392.514.946.514 1.52v5.888z" />
    </svg>
  );
}
