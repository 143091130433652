import React from 'react';
import styles from './styles.module.css';

import Logo from '@components/Logo';

export default function ControlPanel({ children }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logo}>
        <Logo imgClassName={styles.image} className={styles.link} height={25} />
      </div>
      {children}
    </div>
  );
}
