import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 489.057 489.057"
      width={width}
      height={height}
      className={className}
    >
      <path d="M461.171 404.385c16.939-27.268 25.857-58.525 25.857-90.871 0-42.898-15.851-84.018-44.633-115.783-25.908-28.594-60.347-47.542-97.969-54.125C330.224 62.695 259.447 1.014 174.528 1.014c-95.117 0-172.5 77.383-172.5 172.5 0 32.345 8.918 63.603 25.857 90.871L0 348.042l83.657-27.885a171.198 171.198 0 0 0 60.966 23.274c6.586 37.614 25.532 72.046 54.121 97.95 31.766 28.782 72.886 44.633 115.784 44.633 32.347 0 63.604-8.918 90.871-25.857l83.657 27.885-27.885-83.657zM88.098 287.054l-40.664 13.554 13.555-40.662-4.199-6.146c-16.198-23.706-24.761-51.468-24.761-80.286 0-78.575 63.925-142.5 142.5-142.5s142.5 63.925 142.5 142.5-63.925 142.5-142.5 142.5c-28.82 0-56.582-8.562-80.286-24.76l-6.145-4.2zm353.525 153.555l-40.663-13.554-6.146 4.199c-23.704 16.199-51.467 24.761-80.286 24.761-67.188 0-124.045-46.204-138.79-110.015 94.158-.652 170.623-77.118 171.275-171.275 63.811 14.746 110.015 71.603 110.015 138.79 0 28.818-8.563 56.581-24.761 80.286l-4.199 6.146 13.555 40.662z" />
    </svg>
  );
}
