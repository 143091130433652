import React from 'react';

export default function({ width = 50, height = 50, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 414.221 414.221"
      width={width}
      height={height}
      className={className}
    >
      <path d="M301.829 23.599A202.967 202.967 0 0 0 207.375 0C95.283-.02 4.398 90.832 4.378 202.924A202.96 202.96 0 0 0 61.934 344.56l-8.48 60.32a8 8 0 0 0 12.32 8l46.08-30.56c99.058 52.46 221.888 14.684 274.348-84.374s14.685-221.888-84.373-274.347zm38.581 310.716A186.959 186.959 0 0 1 207.375 390a187.04 187.04 0 0 1-91.92-24 8 8 0 0 0-8 0l-36.08 23.44 6.96-46.48a8 8 0 0 0-2.32-6.8C2.495 263.659 1.669 145.285 74.17 71.765S265.045-2.581 338.565 69.92s74.346 190.875 1.845 264.395z" />
      <path d="M253.215 274.8h-6.64V156.56a8 8 0 0 0-8-8h-69.04a8 8 0 0 0-8 8v28.8a8 8 0 0 0 8 8h6.72v81.44h-6.72a8 8 0 0 0-8 8v28.8a8 8 0 0 0 8 8h83.68a8 8 0 0 0 8-8v-28.8a8 8 0 0 0-8-8zm-75.68 28.8v-12.8h6.72a8 8 0 0 0 8-8v-97.44a8 8 0 0 0-8-8h-6.72v-12.8h52.96V282.8a8 8 0 0 0 8 8h6.64l.08 12.8h-67.68zM207.375 70.4c-18.513 0-33.52 15.007-33.52 33.52 0 18.513 15.007 33.52 33.52 33.52s33.52-15.007 33.52-33.52c0-18.513-15.008-33.52-33.52-33.52zm0 51.12c-9.676 0-17.52-7.844-17.52-17.52-.044-9.676 7.764-17.556 17.44-17.6h.08v.08c9.676 0 17.52 7.844 17.52 17.52s-7.844 17.52-17.52 17.52z" />
    </svg>
  );
}
